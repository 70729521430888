@tailwind base;
@tailwind components;
@tailwind utilities;

.h-screen {
    height: 100vh;
    height: 100dvh;
}

@layer base {
    button:focus {
        outline: none;
    }
}

@layer utilities {
    .print-color-adjust {
        -webkit-print-color-adjust: exact;
    }

    .print-page-break {
        page-break-before: always;
    }
}

@layer components {
    .pulppo-pagination .ant-pagination-item {
        @apply min-w-[1rem] border-none relative inline-flex items-center justify-center rounded-md px-2 py-2 text-sm font-semibold hover:bg-gray-200;
    }

    .pulppo-pagination .ant-pagination-item-active {
        @apply z-10 bg-black text-white focus-visible:outline rounded-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black hover:bg-black;
    }

    .pulppo-pagination .ant-pagination-item a {
        @apply text-pulppo-status-dark-disabled;
    }

    .pulppo-pagination .ant-pagination-item-active a {
        @apply text-white;

    }

    .pulppo-pagination .ant-pagination-prev .ant-pagination-item-link, .pulppo-pagination .ant-pagination-next .ant-pagination-item-link {
        @apply border-none relative justify-center bg-transparent inline-flex items-center px-2 py-2 text-pulppo-status-dark-disabled hover:bg-gray-50 focus:z-20 focus:outline-offset-0;
    }

    .btn {
        @apply transition-colors px-3 py-2.5 text-sm font-light text-gray-900 ring-1 ring-inset hover:text-black;
    }

    .btn-primary {
        @apply bg-pulppo-primary-yellow ring-transparent hover:bg-opacity-80 !important;
    }

    .btn-secondary {
        @apply bg-white ring-gray-300 hover:bg-gray-50;
    }

    .btn-black {
        @apply bg-pulppo-primary-black text-white ring-white hover:bg-opacity-90 hover:text-white;
    }
    .btn-black:disabled{
        @apply bg-status-dark-disabled text-pulppo-status-light-disabled cursor-not-allowed
    }

    .btn-disabled, .btn:disabled {
        @apply cursor-not-allowed;
        color: rgba(0, 0, 0, .25);
        background: #f5f5f5;
    }

    .form-item-one-column {
        @apply [&>div>div]:text-left [&>div]:flex-col;
    }

    .container-operation-form {
        @apply flex-1 overflow-y-auto;
    }
}
